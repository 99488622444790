<template>
  <div id="export-marketing-select-type-export">
    <div class="card p-4">
      <select id="select-event" class="form-control" v-model="selectTypeExport" v-on:change="onChangeSelect">
        <option disabled :selected="!selectTypeExport" :value="null">Choisir un type d'export</option>
        <option v-for="(type, index) in listTypeExport" :key="index" :value="type.slug">
          {{ type.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

export default {
  props: {
  },
  data() {
    return {
      listTypeExport: [
        { label: 'Centre', slug: 'center' },
        { label: 'Categorie', slug: 'category' },
        { label: 'Magasin', slug: 'shop' }
      ],
      selectTypeExport: null
    }
  },
  created() {

  },
  methods: {
    onChangeSelect()
    {
      this.$emit('emit-on-change-select-type', this.selectTypeExport)
    }
  },
  components: {
  }
}
</script>
